/* global config, moment */

'use strict';

angular.module('managerApp').controller('PricesController', ($scope, toast, prices, procedureTools) => {

    $scope.config = config;

//------------------------------------------------------------------------------

    const DEFAULT_ERROR_MESSAGE = config.app.defaultErrorMessage;
    $scope.prices = [];
    
    $scope.deviceTypes = [
        {
            name: 'app-mobile',
            label: 'Appli mobile',
            icon: 'phone_android',
            b2b: false
        },
        {
            name: 'app-tablette-pro',
            label: 'Tablette',
            icon: 'tablet_android',
            b2b: true
        },
        {
            name: 'borne',
            label: 'Borne',
            icon: 'touch_app',
            b2b: true
        },
        {
            name: 'site-web',
            label: 'Site web',
            icon: 'laptop',
            b2b: true
        }
    ];

//------------------------------------------------------------------------------

    $scope.savePrice = (_price) => {
        let price = Object.assign({}, _price);
        if (_price._id) {
            for (let i in price) {
                if (['_id', 'price'].indexOf(i) < 0) {
                    delete price[i];
                }
            }
            if (price.price === '') {
                price.price = null;
            } else {
                price.price = parseFloat(price.price);
            }
            prices.update(_price._id, price).then((updatedPrice) => {
                if (updatedPrice.deleted === true) {
                    delete _price._id;
                    setTimeout(() => {
                        $scope.$apply();
                    }, 50);
                }
                toast.show('Le prix de la démarche a bien été enregistré.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        } else {
            prices.create(price).then((newPrice) => {                
                _price._id = newPrice._id;
                setTimeout(() => {
                    $scope.$apply();
                }, 50);
                toast.show('Le prix de la démarche a bien été enregistré.', 'success');
            }).catch((err) => {
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        }
    };

    $scope.getProcedureName = (procedureType) => {
        return procedureTools.getProcedureName(procedureType);
    };

    function getPrices() {

        $scope.procedureTypes = procedureTools.getAllProcedureTypes();
        let b2c = {
            'app-mobile': [],
            'app-tablette-pro': [],
            'borne': [],
            'site-web': []
        };
        let b2b = {
            'app-mobile': [],
            'app-tablette-pro': [],
            'borne': [],
            'site-web': []
        };

        for (let i = 0; i < $scope.procedureTypes.length; i++) {
            let t = $scope.procedureTypes[i];
            for (let c in b2c) {
                b2c[c].push({
                    b2b: false,
                    deviceType: c,
                    procedure: t.name,                    
                    DevicePartnerId: 'RCG',
                    procedureLabel: t.label                    
                });
            }
            for (let b in b2b) {
                b2b[b].push({
                    b2b: true,
                    deviceType: b,
                    procedure: t.name,
                    procedureLabel: t.label,
                    DevicePartnerId: 'RCG_B2B'
                });
            }
        }
        prices.get({}).then((_prices) => {

            let pricesB2C = _prices.filter(price => price.b2b === false);
            let pricesB2B = _prices.filter(price => price.b2b === true);

            for (let j in b2c) {
                let a = pricesB2C.filter(price => price.deviceType === j);
                for (let k = 0; k < b2c[j].length; k++) {
                    for (let b = 0; b < a.length; b++) {
                        if (a[b].procedure === b2c[j][k].procedure) {
                            b2c[j][k]._id = a[b]._id;
                            b2c[j][k].price = a[b].price;
                            break;
                        }
                    }
                }
            }
            
            for (let j in b2b) {
                let a = pricesB2B.filter(price => price.deviceType === j);
                for (let k = 0; k < b2b[j].length; k++) {
                    for (let b = 0; b < a.length; b++) {
                        if (a[b].procedure === b2b[j][k].procedure) {
                            b2b[j][k]._id = a[b]._id;
                            b2b[j][k].price = a[b].price;
                            break;
                        }
                    }
                }
            }

            $scope.prices = {
                b2c: b2c,
                b2b: b2b
            };

            $scope.$apply();
        });
    }

//------------------------------------------------------------------------------

    getPrices();

});